import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../common/api";
import ConfigContext from "../../common/context/ConfigContext";
import { useNavigate } from "react-router";
import RequestSummary from "../component/RequestSummary";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { generateProductSnippets, getAddress, isAssistedCheckout } from "../../common/util";
import moment from "moment";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LineItem from "../../common/component/LineItem";
import QuoteChat from "../component/QuoteChat";
import SendMessage from "../component/SendMessage";
import Images from "../../common/component/image/Images";
import IconText from "../../common/component/IconText";
import InfoPopover from "../../common/component/InfoPopover";
import currency from "currency.js";
import HTMLViewer from "../../common/component/HTMLViewer";
import { CheckOutlined } from "@mui/icons-material/";
import ExpandableImage from "../../common/component/image/ExpandableImage";
import LineItemSelection from "../../common/component/LineItemSelection";

export default function QuoteV4() {
  const siteConfig = useContext(ConfigContext);
  const navigate = useNavigate();

  const ref = useRef(null);

  const [request, setRequest] = useState("");
  const [quote, setQuote] = useState("");

  const [items, setItems] = useState("");

  const [flavor, setFlavor] = useState("");
  const [filling, setFilling] = useState("");
  const [inscription, setInscription] = useState("");

  const [flavors, setFlavors] = useState("");
  const [fillings, setFillings] = useState("");

  const { reqId } = useParams();

  const [loading, setLoading] = useState(true);

  const staffAssist = isAssistedCheckout();

  useEffect(() => {
    bkstApi(`/site/${siteConfig.id}/quote/${reqId}`).then((res) => {
      document.title = res.data.request.title;
      setRequest(res.data.request);
      setQuote(res.data.quote);

      if(res.data.quote.items){
        setItems(res.data.quote.items);
      }

      if (res.data.options?.flavors) {
        setFlavors(res.data.options.flavors);
        //setFlavor(Object.values(res.data.options.flavors).filter((o) => o.default)[0]);
      }

      if (res.data.options?.fillings && res.data.quote?.productType === "Cake") {
        setFillings(res.data.options.fillings);
        //setFilling(Object.values(res.data.options.fillings).filter((o) => o.default)[0]);
      }
      setLoading(false);

      ref.current.scrollIntoView();
    });
  }, [reqId]);

  const addToCart = (e) => {
    e.preventDefault();

    const item = {
      type: "quote",
      refId: request.id,
      quantity: 1,
      variant: { flavor: [{ name: flavor?.name, filling: filling?.name }] },
      customization: { inscription: { text: inscription } }
    };

    let payload = { item };
    if (items) {
      payload.items = items;
    }

    bkstApi.post(`/site/${siteConfig.id}/cart`, payload).then((res) => {
      navigate(`/cart/${res.data.cartId}`);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    let quoteStatus = quote.status;
    let expiration = quote.exdate && quote.exdate.includes("T") ? quote.exdate : `${quote.exdate}T23:59`;
    if (quote.status === "active" && moment(expiration).diff(moment(), "minutes") < 0) {
      quoteStatus = "expired";
    } else if (quote.status === "block") {
      quoteStatus = "unavailable";
    }

    let chatButton = <Box />;
    if (quote.items) {
      chatButton = <QuoteChat reqId={request.id} baker={quote.baker} name={quote.baker.bizName} chat={quote.chat} />;
    } else {
      chatButton = (
        <Box my={4}>
          <Typography variant="overline" color="textSecondary">
            Contact
          </Typography>
          <Paper elevation={0}>
            <Box px={2} py={2}>
              <Box mb={2}>
                <Typography variant="body2" gutterBottom>
                  Have Questions?
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Send us a message & we'll respond within a few hours.
                </Typography>
              </Box>
              <SendMessage
                cta="Send a Message"
                fullWidth={true}
                reqId={request.id}
                baker={quote.baker}
                name={quote.baker.bizName}
                chat={quote.chat}
                color="primary"
                variant="outlined"
              />
            </Box>
          </Paper>
        </Box>
      );
    }

    if (request?.status === "new" || request?.status === "pending") {
      return (
        <Container maxWidth="sm" ref={ref} sx={{ paddingBottom: "20em" }}>
          <Box my={4} py={2}>
            <Alert severity="success">
              <AlertTitle>Thank you for requesting a quote!</AlertTitle>
              We'll review the details & send you a quote shortly. You'll receive a text message when your quote is ready. We look forward to serving
              you!
            </Alert>
          </Box>
          <Box my={4}>
            <RequestSummary request={request} />
          </Box>
          {chatButton}
        </Container>
      );
    } else if (quoteStatus === "unavailable") {
      return (
        <Container maxWidth="sm" ref={ref} sx={{ paddingBottom: "20em" }}>
          <Box my={4} py={2} textAlign="center">
            <SentimentVeryDissatisfiedIcon sx={{ fontSize: "5rem", color: "#e74c3c" }} />
            <Typography variant="h4" gutterBottom>
              We're Sorry!
            </Typography>
            <Typography variant="body1" color="textSecondary">
              We are unable to provide a quote at this time
            </Typography>
          </Box>
          <Box my={4}>
            <Alert severity="error">
              <AlertTitle>Reason</AlertTitle>
              {quote.note ||
                `This is because we may have sent you another quote and this is a duplicate. Or, you may have informed us that you're no longer interested in a quote. If you believe this is an error, please message us below.`}
            </Alert>
          </Box>
          <Box my={4}>
            <RequestSummary request={request} />
          </Box>
          {chatButton}
        </Container>
      );
    } else if (quoteStatus === "active" || quoteStatus === "expired") {
      const snippets = generateProductSnippets(quote.productType, quote.shape, quote.size, quote.servings);
      const address = (request.eventLocation && getAddress(request.eventLocation)) || request.location;

      if (items) {
        return (
          <Container maxWidth="sm" ref={ref} sx={{ paddingBottom: "20em" }}>
            <form onSubmit={addToCart}>
              <Box my={4}>
                <Box>
                  <Typography variant="h2" gutterBottom>
                    👋 {request.firstName || request.name || ""},
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    Here's your Custom Quote!
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Please review the details below & let us know if you have any questions. When you're ready to order, simply scroll down & tap the
                    Proceed to Checkout button.{" "}
                  </Typography>
                </Box>
                <Box my={4}>
                  <Typography variant="overline" color="textSecondary">
                    ITEMS
                  </Typography>

                  {items.map((item, idx) => (
                    <Box key={idx} my={2}>
                      <Paper elevation={0}>
                        <Box p={2}>
                          <LineItem item={item} />
                          {quoteStatus === "active" && (
                            <LineItemSelection
                              item={item}
                              onChange={(o) => {
                                console.log('update', o);
                                let arr = [...items];
                                arr[idx] = o;
                                setItems(arr);
                              }}
                            />
                          )}
                        </Box>
                      </Paper>
                    </Box>
                  ))}
                </Box>
                <Box my={2}>
                  {quoteStatus === "expired" ? (
                    <Alert severity="error">
                      Your quote has expired. If you're interested in booking, please message us below to check availability.
                    </Alert>
                  ) : (
                    <Alert severity="info">
                      Quote expires{" "}
                      <u>
                        <b>{moment(expiration).fromNow()}</b>
                      </u>
                      . Price is subject to change after quote expires.
                    </Alert>
                  )}
                </Box>
              </Box>
              {quote.fulfillment.type === "delivery" && (
                <Box my={2}>
                  <Alert severity="success" icon={<LocalShippingOutlinedIcon />}>
                    Delivery is available! Choose the delivery option in checkout.
                  </Alert>
                </Box>
              )}
              {quoteStatus === "active" && (
                <Box my={4}>
                  <Button fullWidth variant="contained" color="primary" type="submit">
                    PROCEED TO CHECKOUT
                  </Button>
                </Box>
              )}
            </form>
            {!staffAssist && chatButton}
          </Container>
        );
      } else {
        return (
          <Container maxWidth="sm" ref={ref} sx={{ paddingBottom: "20em" }}>
            <Box my={4} py={2}>
              {quoteStatus === "expired" && (
                <Box my={4}>
                  <Alert severity="error">
                    Your quote has expired. If you're interested in booking, please message us below to check availability.
                  </Alert>
                </Box>
              )}
              <Paper elevation={0}>
                <Box p={3}>
                  <Grid container spacing={2}>
                    <Grid item sm={4} xs={3}>
                      <Images pics={request.pics} />
                    </Grid>
                    <Grid item sm={8} xs={9}>
                      <Typography variant="h5" gutterBottom>
                        {request.title}
                      </Typography>
                      {quoteStatus === "expired" ? (
                        <Box>
                          <Chip size="large" label={currency(quote.quote).format()} style={{ textDecoration: "line-through" }} />
                          <Typography variant="caption" color="textSecondary">
                            &nbsp;quote expired&nbsp;
                            <InfoPopover
                              info
                              text="Your quote has expired. If you're interested in booking, please message us below to check availability."
                            />
                          </Typography>
                        </Box>
                      ) : (
                        <Box>
                          <Chip size="large" label={currency(quote.quote).format()} style={{ fontWeight: "700" }} />
                          <Typography variant="caption" color="textSecondary">
                            &nbsp;expires <u>{moment(expiration).fromNow()}</u>&nbsp;
                            <InfoPopover info text="We have limited availability & orders are accepted on a first-come, first-serve basis" />
                          </Typography>
                        </Box>
                      )}
                      <Box mt={2}>
                        {snippets.map((e, idx) => (
                          <IconText key={idx} icon={CheckOutlined} text={e} variant="body2" />
                        ))}
                        {quote.tags && quote.tags.map((e, idx) => <IconText key={idx} icon={CheckOutlined} text={e} variant="body2" />)}
                        <IconText icon={CheckOutlined} text={`Custom, made-to-order`} variant="body2" />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ borderTop: "1px solid #ECECEC" }}>
                        {quote.note && (
                          <Box pt={1}>
                            <HTMLViewer content={quote.note} style={{ fontSize: ".85rem" }} />
                          </Box>
                        )}
                        {quote.pics &&
                          quote.pics.map((p, idx) => (
                            <span key={idx} style={{ marginRight: "15px" }}>
                              <ExpandableImage src={p} />
                            </span>
                          ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
              {(quote.option == "free" || quote.option == "fee") && (
                <Box my={4}>
                  {quote.option == "free" && (
                    <Alert severity="success" icon={<LocalShippingOutlinedIcon />}>
                      Free Delivery option is available to your {address} location. Choose delivery in checkout.
                    </Alert>
                  )}
                  {quote.option == "fee" && (
                    <Alert severity="info" icon={<LocalShippingOutlinedIcon />}>
                      Delivery is available to your {address} location. Delivery fee is ${Math.round(quote.delivery)}. Choose delivery in checkout.
                    </Alert>
                  )}
                </Box>
              )}
              {!staffAssist && chatButton}
              {quoteStatus === "active" && (
                <Box>
                  <form onSubmit={addToCart}>
                    {(quote.productType === "Cake" || quote.productType === "Cupcakes" || quote.productType === "Cake Pops") && (
                      <Box my={4}>
                        <Typography variant="overline" color="textSecondary">
                          Flavor
                        </Typography>
                        <Paper elevation={0}>
                          <Box px={2} py={3}>
                            {!staffAssist && (
                              <Box mb={4}>
                                <Typography variant="body2" gutterBottom>
                                  Not Sure?
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  Not a problem! Simply pick one for now & proceed with your order. You can always change it later, as long as you let
                                  us know a week in advance.
                                </Typography>
                              </Box>
                            )}
                            {(flavor?.type === "premium" || filling?.type === "premium") && (
                              <Box mb={2}>
                                <Alert severity="info">
                                  You've selected a premium option. There may be an additional charge once your order is confirmed.
                                </Alert>
                              </Box>
                            )}
                            <Box>
                              <Grid container spacing={4}>
                                <Grid item sm={12} xs={12}>
                                  {flavors && Object.values(flavors).length > 0 && (
                                    <FormControl fullWidth>
                                      <Autocomplete
                                        value={flavor}
                                        onChange={(e, v) => setFlavor(v)}
                                        options={flavors ? Object.values(flavors).sort((o1, o2) => (o1.name > o2.name ? 1 : -1)) : []}
                                        getOptionLabel={(option) => option.name || ""}
                                        renderOption={(props, option) => (
                                          <li {...props}>
                                            <Box>
                                              <Typography variant="body1">
                                                {option.name}{" "}
                                                {option.type === "premium" && <Chip size="small" color="info" label={`+ $${option.fee}`} />}{" "}
                                                {option.default && <Chip size="small" label={`bestseller`} />}
                                              </Typography>
                                              <Typography variant="caption" color="textSecondary">
                                                {option.desc}
                                              </Typography>
                                            </Box>
                                          </li>
                                        )}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Flavor" required />}
                                        disableClearable
                                        selectOnFocus
                                        clearOnBlur
                                      />
                                      <FormHelperText>{flavor?.desc}</FormHelperText>
                                    </FormControl>
                                  )}
                                </Grid>
                                {quote.productType === "Cake" && fillings && Object.values(fillings).length > 0 && (
                                  <Grid item sm={12} xs={12}>
                                    <FormControl fullWidth>
                                      <Autocomplete
                                        value={filling}
                                        onChange={(e, v) => setFilling(v)}
                                        options={fillings ? Object.values(fillings).sort((o1, o2) => (o1.name > o2.name ? 1 : -1)) : []}
                                        getOptionLabel={(option) => option.name || ""}
                                        renderOption={(props, option) => (
                                          <li {...props}>
                                            <Box>
                                              <Typography variant="body1">
                                                {option.name}{" "}
                                                {option.type === "premium" && <Chip size="small" color="info" label={`+ $${option.fee}`} />}{" "}
                                                {option.default && <Chip size="small" label={`bestseller`} />}
                                              </Typography>
                                              <Typography variant="caption" color="textSecondary">
                                                {option.desc}
                                              </Typography>
                                            </Box>
                                          </li>
                                        )}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Filling" required />}
                                        filterSelectedOptions
                                        disableClearable
                                        selectOnFocus
                                        clearOnBlur
                                      />
                                      <FormHelperText>{filling?.desc}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                )}
                              </Grid>
                            </Box>
                          </Box>
                        </Paper>
                      </Box>
                    )}
                    {quote.productType === "Cake" && (
                      <Box my={4}>
                        <Typography variant="overline" color="textSecondary">
                          Inscription
                        </Typography>
                        <Paper elevation={0}>
                          <Box px={2} py={3}>
                            {!staffAssist && (
                              <Box mb={4}>
                                <Typography variant="body2" gutterBottom>
                                  What to write?
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  Add a greeting like "Happy Birthday", a name like "Alice" or a number like "4"
                                </Typography>
                              </Box>
                            )}
                            <TextField
                              id="inscription"
                              value={inscription}
                              label="Inscription (optional)"
                              name="inscription"
                              variant="outlined"
                              fullWidth
                              onChange={(e) => setInscription(e.target.value)}
                            />
                          </Box>
                        </Paper>
                      </Box>
                    )}
                    <Box my={4}>
                      <Button fullWidth variant="contained" color="primary" type="submit">
                        PROCEED TO CHECKOUT
                      </Button>
                    </Box>
                  </form>
                </Box>
              )}
            </Box>
          </Container>
        );
      }
    } else {
      // closed?
      return <Box />;
    }
  }
}
