import React, { useContext } from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import ConfigContext from "../context/ConfigContext";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { getStaticMapUrl } from "../util";
import StoreLocation from "./StoreLocation";
import moment from "moment";

export default function Footer(props) {
  const siteConfig = useContext(ConfigContext);

  const staticMapUrl = getStaticMapUrl(siteConfig.stores);

  return (
    <Box mt={20} pt={20} pb={5} sx={{ backgroundColor: "#FFFFFF" }}>
      <Container>
        <Typography variant="h5" gutterBottom>
          Visit Us
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Box mb={2}>
              {siteConfig.stores.map((store, idx) => (
                <StoreLocation key={idx} store={store} index={idx} />
              ))}
            </Box>
          </Grid>
          <Grid item sm={8} xs={12}>
            <img style={{ width: "100%" }} src={staticMapUrl} />
            <Box sx={{ textAlign: "right" }}>
              <Typography variant="caption" color="textSecondary">
                {process.env.REACT_APP_VERSION}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box py={7} textAlign={"center"}>
          <Box py={2}>
            {siteConfig.ig && (
              <Link color="inherit" style={{ padding: ".5rem" }} target="_blank" href={siteConfig.ig} underline="hover">
                <InstagramIcon />
              </Link>
            )}
            {siteConfig.fb && (
              <Link color="inherit" style={{ padding: ".5rem" }} target="_blank" href={siteConfig.fb} underline="hover">
                <FacebookIcon />
              </Link>
            )}
          </Box>
          <Typography color="textSecondary" variant="caption">
            &copy; {moment().format('YYYY')} {siteConfig.biz}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
