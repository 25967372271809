import React, { useState, useRef } from "react";
import { Box, Link } from "@mui/material";
import { isTruncated } from "../../common/util";

export default function ProductDescription(props) {
  const ref = useRef(null);

  const { description } = props;

  const [truncate, setTruncate] = useState(true);

  const showMore = isTruncated(ref.current);

  return (
    <Box>
      <Box
        ref={ref}
        style={{
          color: "#777",
          fontSize: ".9rem",
          overflow: "hidden",
          display: "-webkit-box",
          "-webkit-line-clamp": truncate ? "2" : "100",
          "line-clamp": truncate ? "2" : "100",
          "-webkit-box-orient": "vertical"
        }}
        dangerouslySetInnerHTML={{
          __html: description
        }}
      />
      {truncate && showMore && (
        <Link
          color="primary"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setTruncate(false);
          }}
        >
          show more
        </Link>
      )}
    </Box>
  );
}
