import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Box, Container, LinearProgress, Typography } from "@mui/material";
import OrderDetails from "../component/OrderDetails";
import OrderFulfillment from "../component/OrderFulfillment";
import OrderPayment from "../component/OrderPayment";
import bkstApi from "../../common/api";
import OrderInvoices from "../component/OrderInvoices";
import OrderCareInstructions from "../component/OrderCareInstructions";
import OrderCancelationPolicy from "../component/OrderCancelationPolicy";
import LatePaymentPolicy from "../component/LatePaymentPolicy";
import OrderRefundPolicy from "../component/OrderRefundPolicy";
import OrderChat from "../component/OrderChat";
import moment from "moment";
import ConfigContext from "../../common/context/ConfigContext";

export default function Order(props) {
  const ref = useRef(null);
  const siteConfig = useContext(ConfigContext);

  const { orderId } = useParams();
  const [order, setOrder] = useState();

  useEffect(() => {
    bkstApi(`/site/${siteConfig.id}/order/${orderId}`).then((res) => {
      document.title = 'Order Details';
      setOrder(res.data);
      ref.current.scrollIntoView();
    });
  }, [orderId]);

  if (!order) {
    return <LinearProgress />;
  } else {
    let showChatWidget = true;
    if (order.status === "completed") {
      // check elapsed time since fulfillment
      const elapsedDaySinceFulfillment = moment().diff(moment(order.fulfillment.date), "days");
      console.log("elapsedDaySinceFulfillment", elapsedDaySinceFulfillment);

      if (elapsedDaySinceFulfillment > 3) {
        showChatWidget = false;

        // check last msg from bakery
        const msgFromBaker = order.customer?.chat?.filter((o) => o.by === "baker") || [];
        const lastMsg = msgFromBaker[msgFromBaker.length - 1];

        if (lastMsg && moment().diff(moment(lastMsg.ts), "days") <= 3) {
          showChatWidget = true;
        }
      }
    }

    return (
      <Container mb={15} maxWidth="sm" ref={ref}>
        <Box my={6}>
          {order.status === "confirmed" && (
            <Box textAlign="center">
              <Typography variant="h2" gutterBottom>
                Order Confirmed
              </Typography>
              <Typography variant="body1" color="textSecondary">{`Your order was confirmed on ${moment(order.ts._seconds * 1000).format(
                "ddd, MMM DD, YYYY h:mm a"
              )}`}</Typography>
            </Box>
          )}
          {order.status === "fulfilled" && (
            <Box textAlign="center">
              <Typography variant="h2" gutterBottom>
                Order Completed
              </Typography>
              <Typography variant="body1" color="textSecondary">
                We look forward to serving you again
              </Typography>
            </Box>
          )}
          {order.status === "completed" && (
            <Box textAlign="center">
              <Typography variant="h2" gutterBottom>
                Order Completed
              </Typography>
              <Typography variant="body1" color="textSecondary">
                We look forward to serving you again
              </Typography>
            </Box>
          )}
          {order.status === "canceled" && (
            <Box textAlign="center">
              <Typography variant="h2" gutterBottom>
                Order Canceled
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Your order has been canceled
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          <Box pt={2}>
            <Typography variant="overline" color="textSecondary">
              Order Details
            </Typography>
            <OrderDetails order={order} />
          </Box>
          <Box my={6}>
            <Typography variant="overline" color="textSecondary">
              {order.fulfillment.type} Details
            </Typography>
            <OrderFulfillment order={order} />
          </Box>
          {order.invoice && <OrderInvoices data={order.invoice} />}
          <Box my={6}>
            <Typography variant="overline" color="textSecondary">
              Payment Details
            </Typography>
            <OrderPayment order={order} />
          </Box>
          {showChatWidget && (
            <Box my={6}>
              <OrderChat orderId={orderId} name={order.baker.name} chat={order.customer.chat} color="primary" variant="outlined" fullWidth />
            </Box>
          )}
          <Box my={6}>
            <OrderCareInstructions />
          </Box>
          <OrderCancelationPolicy
            date={order.fulfillment.date}
            total={order.payment.total}
            tip={order.payment.tip}
            delivery={order.payment.delivery}
            ts={order.ts._seconds * 1000}
          />
          <LatePaymentPolicy />
          <OrderRefundPolicy />
        </Box>
      </Container>
    );
  }
}
