import React, { useContext, useState, useEffect } from "react";
import { Box, Button, Container, Grid, LinearProgress, Paper, Tab, Tabs, Typography, Alert } from "@mui/material";
import bkstApi from "../../common/api";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import ConfigContext from "../../common/context/ConfigContext";
import { AppStateContext } from "../../common/context/AppStateContext";
import ProductSummary from "../component/ProductSummary";
import { capitalize } from "../../common/util";

export default function Collection() {
  const siteConfig = useContext(ConfigContext);
  const { cart } = useContext(AppStateContext);

  const navigate = useNavigate();

  const [tags, setTags] = useState([]);
  const [categoryMap, setCategoryMap] = useState("");

  const [category, setCategory] = useState("");
  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bkstApi(`/site/${siteConfig.id}/product/collection/shop`)
      .then((res) => {
        let sortedByRank = res.data.products.sort((o1, o2) => {
          let rslt = (+o2.rank || 0) - (+o1.rank || 0);
          if (rslt === 0) {
            rslt = o1.title > o2.title ? 1 : -1;
          }
          return rslt;
        });
        setProducts(sortedByRank);

        let map = {};
        for (const p of sortedByRank) {
          const cat = p.type.toLowerCase();
          const arr = map[cat] || [];
          arr.push(p);
          map[cat] = arr;
        }
        setCategoryMap(map);
        setLoading(false);

        if (window.location.hash) {
          setTimeout(() => {
            setCategory(window.location.hash.substring(1));
            scrollToCategory(window.location.hash.substring(1));
          }, 500);
        } else {
          window.scrollTo(0, 0);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const scrollToCategory = (cat) => {
    const el = document.getElementById(cat);
    if (el) {
      el.scrollIntoView();
    }
  };

  const sortCategories = (o1, o2) => {
    if (o1.includes("4th of July") || o1.includes("Featured")) {
      return -1;
    } else if (o2.includes("4th of July") || o2.includes("Featured")) {
      return 1;
    } else {
      return o1.localeCompare(o2);
    }
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="md">
        <Box py={6} align="center">
          {cart?.quantity > 0 && (
            <Box mb={4}>
              <Alert
                severity="success"
                action={
                  <Button color="inherit" size="small" component={RouterLink} to={`/cart/${cart.id}`}>
                    Proceed to Checkout
                  </Button>
                }
              >
                You have {cart.quantity} items in your cart
              </Alert>
            </Box>
          )}
          {/* <Box
            sx={{
              backgroundImage:
                "linear-gradient(45deg , #ECECEC 10%,transparent 11%, transparent 89% , #ECECEC 90%),linear-gradient(135deg , #ECECEC 10%,transparent 11%, transparent 89% , #ECECEC 90%),radial-gradient(circle, transparent 25%, #FEFEFE  26%),linear-gradient(0deg, transparent 44%, #ECECEC 45%, #ECECEC 55%, transparent 56%), linear-gradient(90deg, transparent 44%, #ECECEC 45%, #ECECEC 55%, transparent 56%)",
              backgroundSize: "2em 2em"
            }}
          >*/}
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Typography variant="h2" gutterBottom>
              Order Now
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              Our seasonal & daily selection of delectable treats
            </Typography>
          </Box>
          <Box
            mt={4}
            mb={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#FEFEFE",
              position: "-webkit-sticky",
              position: "sticky",
              top: "0",
              boxShadow: "0px 5px 5px 0px rgba(0, 0, 0, 0.2)"
            }}
          >
            <Tabs value={false} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
              {Object.keys(categoryMap)
                .sort(sortCategories)
                .map((cat, idx) => (
                  <Tab key={idx} label={cat} onClick={() => scrollToCategory(cat)} />
                ))}
            </Tabs>
          </Box>
          {Object.keys(categoryMap)
            .sort(sortCategories)

            .map((key) => {
              return (
                <Box mb={4} id={key} sx={{ scrollMarginTop: "75px" }}>
                  <Box mb={2} textAlign={"left"}>
                    <Typography variant="h4">{capitalize(key)}</Typography>
                  </Box>
                  <Grid container spacing={3}>
                    {categoryMap[key].map((product, index) => {
                      return <ProductSummary key={index} product={product} />;
                    })}
                  </Grid>
                </Box>
              );
            })}
        </Box>
      </Container>
    );
  }
}
