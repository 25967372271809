import React, { useState, useEffect } from "react";
import { Box, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import currency from "currency.js";

export default function ProductVariantSelection(props) {
  const { options, variant } = props;

  const [size, setSize] = useState(variant?.size || "");
  const [flavor, setFlavor] = useState(variant?.flavor || "");
  const [filling, setFilling] = useState(variant?.filling || "");

  useEffect(() => {
    let payload = {};
    if (size) {
      payload.size = size;

      if (size.frosting) {
        payload.frosting = { name: size.frosting };
      }
    }
    if (flavor || filling) {
      let o = {};

      if (flavor) {
        o.base = { name: flavor.name, pricing: flavor.pricing };
      }
      if (filling) {
        o.filling = { name: filling.name, pricing: filling.pricing };
      }
      payload.flavor = o;
    }

    props.onChange(payload);
  }, [size, flavor, filling]);

  return (
    <Box>
      <Grid container spacing={3}>
        {options?.size && (
          <Grid item sm={12} xs={12}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="size-label">Size</InputLabel>
              <Select
                value={variant.size}
                labelId="size-label"
                label={`Size`}
                id="size"
                name="size"
                renderValue={(option) => {
                  return `${option.size} ${option.shape?.toLowerCase() || ""} ${option.servings ? `(${option.servings} servings)` : ""}` || "";
                }}
                onChange={(e) => setSize(e.target.value)}
              >
                {options.size.map((option, idx) => {
                  let title = option.size;
                  if (option.shape) {
                    title += ` ${option.shape?.toLowerCase()}`;
                  }

                  if (option.servings) {
                    title += ` (${option.servings} servings)`;
                  }

                  let description = option.description || "";
                  if (option.frosting && option.shape) {
                    description += `${option.frosting} • ${option.shape}`;
                  }
                  return (
                    <MenuItem key={idx} value={option} style={{ whiteSpace: "normal" }}>
                      <Box>
                        <Typography variant="body1">{title}</Typography>
                        <Typography variant="caption" color="textSecondary">
                          {currency(option.pricing.amount).format()} • {description}
                        </Typography>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        {options?.flavor && (
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="flavor-label">Flavor</InputLabel>
              <Select
                value={variant?.flavor?.base || ""}
                labelId="flavor-label"
                label={`Flavor`}
                id="flavor"
                name="flavor"
                renderValue={(option) => {
                  return option.name;
                }}
                onChange={(e) => setFlavor(e.target.value)}
                required
              >
                {options.flavor
                  .sort((o1, o2) => (o1.name > o2.name ? 1 : -1))
                  .map((option, idx) => (
                    <MenuItem key={idx} value={option} style={{ whiteSpace: "normal" }}>
                      <Box>
                        <Typography variant="body1">
                          {option.name} {+option.pricing?.amount > 0 && <Chip size="small" label={`premium`} />}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {+option.pricing?.amount > 0 ? `$${option.pricing?.amount} surcharge. ` : ""} {option.description}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {options?.filling && (
          <Grid item sm={12} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="filling-label">Filling</InputLabel>
              <Select
                value={variant?.flavor?.filling || ""}
                labelId="filling-label"
                label={`Filling`}
                id="filling"
                name="filling"
                renderValue={(option) => {
                  return option.name;
                }}
                onChange={(e) => setFilling(e.target.value)}
                required
              >
                {options.filling
                  .sort((o1, o2) => (o1.name > o2.name ? 1 : -1))
                  .map((option, idx) => (
                    <MenuItem key={idx} value={option} style={{ whiteSpace: "normal" }}>
                      <Box>
                        <Typography variant="body1">
                          {option.name} {+option.pricing?.amount > 0 && <Chip size="small" label={`premium`} />}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {+option.pricing?.amount > 0 ? `$${option.pricing?.amount} surcharge. ` : ""} {option.description}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
