import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert, Box, Container, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Typography } from "@mui/material";
import bkstApi from "../../common/api";
import currency from "currency.js";
import ConfigContext from "../../common/context/ConfigContext";
import { getBookingMetadata } from "../../common/util";
import ImageCarousel from "../../common/component/image/ImageCarousel";
import BDatePicker from "../../common/component/BDatePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import RequestToBook from "../../common/component/RequestToBook";

export default function ClassPage() {
  const siteConfig = useContext(ConfigContext);

  const [product, setProduct] = useState("");
  const { productId } = useParams();

  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [attendees, setAttendees] = useState("");

  const [stage, setStage] = useState("loading");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setStage("loading");

    bkstApi(`/site/${siteConfig.id}/class/${productId}`).then((res) => {
      setProduct(res.data.product);

      window.scrollTo(0, 100);
      setStage("loaded");
    });
  }, [productId]);

  const validate = () => {
    let errors = [];
    if (!date) {
      errors.push("Date is missing");
    }
    if (!startTime) {
      errors.push("Start time is missing");
    }
    /*if(!attendees){
      errors.push("Attendee count is required");
    }*/

    setErrors(errors);
    return errors.length === 0;
  };

  const submitInquiry = (contactInfo) => {
    bkstApi
      .post(`/site/${siteConfig.id}/product/${productId}/inquiry`, { variant: { date, startTime }, customer: contactInfo, title: product.title })
      .then((res) => {
        setDate("");
        setStartTime("");

        setStage("done");
      });
  };

  if (stage === "loading") {
    return <LinearProgress />;
  } else {
    const metadata = getBookingMetadata(product);

    let attendeeOptions = [];
    if (product.rules?.capacity) {
      const min = +product.rules.capacity.min;
      const max = +product.rules.capacity.max;
      attendeeOptions = Array.from({ length: max - min + 1 }, (_, i) => i + min);
    }

    return (
      <Box>
        <Container maxWidth="lg">
          <Box my={10}>
            <Grid container spacing={6}>
              <Grid item sm={7} xs={12}>
                <ImageCarousel images={product.images} style={{ width: "100%", borderRadius: "4px" }} />
              </Grid>
              <Grid item sm={5} xs={12}>
                <Paper elevation={0}>
                  <Box px={3} py={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box>
                          <Typography variant="h3" gutterBottom>
                            {product.title}
                          </Typography>
                          <Typography variant="body2">{product.subtitle}</Typography>
                        </Box>
                        <Box my={2} py={2} sx={{ borderTop: "1px solid #DDD", borderBottom: "1px solid #DDD" }}>
                          {product.variant?.duration && (
                            <Box my={0.75}>
                              <Typography style={{ display: "flex", alignItems: "center", fontSize: "1em" }}>
                                <AccessTimeIcon style={{ fontSize: "1.2em", marginRight: "5px" }} />
                                {product.variant.duration} hours
                              </Typography>
                            </Box>
                          )}
                          {product.rules?.capacity && (
                            <Box my={0.75}>
                              <Typography style={{ display: "flex", alignItems: "center", fontSize: "1em" }}>
                                <FaceOutlinedIcon style={{ fontSize: "1.2em", marginRight: "5px" }} />
                                {product.rules?.capacity.min} - {product.rules?.capacity.max} attendees
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <div
                          style={{ color: "#777" }}
                          dangerouslySetInnerHTML={{
                            __html: product.description
                          }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <FormControl variant="outlined" fullWidth>
                          <BDatePicker
                            value={date || null}
                            label={`Date`}
                            onChange={(d) => setDate(d)}
                            disablePast
                            required
                            //shouldDisableDate={(d) => shouldDisableDate(d, dateRules, location)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="startTime-label">{`Time`}</InputLabel>
                          <Select
                            variant="outlined"
                            value={startTime}
                            labelId="startTime-label"
                            label="Time"
                            id="startTime"
                            name="startTime"
                            onChange={(e) => setStartTime(e.target.value)}
                          >
                            {["10am", "11am", "12pm", "1pm", "2pm", "3pm", "4pm", "5pm"].map((i) => (
                              <MenuItem key={i} value={i}>
                                {i}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* attendeeOptions && attendeeOptions.length > 0 && (
                        <Grid item xs={12}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="attendees-label">{`Attendees`}</InputLabel>
                            <Select
                              variant="outlined"
                              value={attendees}
                              labelId="attendees-label"
                              label="attendees"
                              id="attendees"
                              name="Attendees"
                              onChange={(e) => setAttendees(e.target.value)}
                            >
                              {attendeeOptions.map((i) => (
                                <MenuItem key={i} value={i}>
                                  {i}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )*/}
                      <Grid item xs={12} textAlign="center">
                        {errors?.length > 0 && (
                          <Box mb={2}>
                            <Alert severity="error">{errors.join(". ")}</Alert>
                          </Box>
                        )}
                        {stage === "done" && <Alert severity="success">We've received your inquiry and we'll be in touch shortly.</Alert>}
                        <Box my={2}>
                          <RequestToBook validate={validate} onSubmit={(payload) => submitInquiry(payload)} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    );
  }
}
