import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import bkstApi from "../../common/api";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ChatCanvas from "../../common/component/chat/ChatCanvas";
import NewMessage from "../../common/component/chat/NewMessage";

export default function OrderChat(props) {
  const { orderId } = props;
  const ref = useRef(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(window.location.hash.includes("chat"));
  const [chat, setChat] = useState(props.chat);

  useEffect(() => {
    if (open) {
      bkstApi(`/order/${orderId}/chat`).then((res) => {
        setChat(res.data.chat || []);
      });
    }
  }, [open]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView();
    }
  }, [chat]);

  const handleNewMsg = (msg) => {
    const allMsgs = [...chat, msg];
    setChat(allMsgs);
  };

  return (
    <Box display="inline">
      <Button
        fullWidth={props.fullWidth || false}
        size="small"
        variant={props.variant || "outlined"}
        color={props.color || "secondary"}
        onClick={(e) => setOpen(true)}
      >
        Chat
      </Button>
      <Dialog fullScreen={fullScreen} fullWidth maxWidth={fullScreen ? false : "sm"} open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ margin: "0" }}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Contact {props.name}</Box>
            <Box>
              <IconButton color="inherit" size="small" onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className="chat-bg" style={{ minHeight: "50vh", padding: "0", margin: "0", display: "flex", flexDirection: "column" }}>
          <Box mb={1} sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
            <ChatCanvas chat={chat} uid={props.uid} />
            <Box textAlign="center" ref={ref}>
              <Chip label="VIEW ORDER" color="primary" onClick={() => setOpen(false)} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ margin: 0, border: 0, paddingBottom: "10px", paddingRight: "10px", paddingLeft: 0, paddingTop: 0 }}>
          <Box sx={{ flexGrow: "1" }}>
            <NewMessage handleNewMsg={handleNewMsg} uploadFolder={`img/o/${orderId}`} postUrl={`/order/${orderId}/chat`} />
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
