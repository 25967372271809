import { useState } from "react";
import { Box, Button, FormControl, FormHelperText, Grid, Paper, TextField, Typography } from "@mui/material";
import BDatePicker from "../../common/component/BDatePicker";
import PlacesAutocomplete from "../../common/component/PlacesAutocomplete";

export default function Event(props) {
  const [location, setLocation] = useState("");
  const [date, setDate] = useState("");
  const [servings, setServings] = useState("");
  const [budget, setBudget] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    props.onSubmit({ location, date, servings, budget });
  };

  const onLocationChange = (value) => {
    setLocation(value);
    document.activeElement.blur();
  };

  return (
    <Paper>
      <Box px={2} pt={7} pb={10} textAlign="center">
        <Box>
          <Grid container>
            <Grid item sm={3} xs={12}></Grid>
            <Grid item sm={6} xs={12}>
              <Box mb={5}>
                <Typography variant="h4" gutterBottom>
                  Tell us about your event
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  This helps us provide you with an accurate quote
                </Typography>
              </Box>
              <form onSubmit={onSubmit}>
                <Grid container spacing={3} align="left">
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <PlacesAutocomplete onChange={onLocationChange} value={location} required helperText="Where is your event?" />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <BDatePicker value={date || null} label="Event Date" onChange={(d) => setDate(d)} disablePast required />
                      <FormHelperText>When is your event?</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={servings}
                        variant="outlined"
                        type="number"
                        id="servings"
                        label="Servings"
                        name="servings"
                        fullWidth
                        required
                        onChange={(e) => setServings(e.target.value)}
                        inputProps={{
                          "data-hj-allow": ""
                        }}
                        helperText="How many guests do you plan to serve?"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={budget}
                        variant="outlined"
                        fullWidth
                        required
                        type="number"
                        id="budget"
                        label="Your Budget"
                        name="budget"
                        onChange={(e) => setBudget(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                        inputProps={{
                          "data-hj-allow": ""
                        }}
                        helperText="Helps us suggest alternatives within your budget"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Button size="large" color="primary" variant="contained" type="submit" fullWidth>
                      Continue
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}