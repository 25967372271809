import React from "react";
import { Box, LinearProgress } from "@mui/material";

const MIN = 0;
const MAX = 5;
const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

export default function ReviewRatingBar(props) {
  const value = Number(props.value);
  let color = "#00b894";
  if (value <= 2) {
    color = "#e17055";
  } else if (value === 3) {
    color = "#00cec9";
  }

  return (
    <Box sx={{ color }}>
      <LinearProgress color="inherit" variant="determinate" value={normalise(Number(props.value))} />
    </Box>
  );
}
