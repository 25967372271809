import React, { useState, useEffect } from "react";
import { Box, Grid, TextField } from "@mui/material";

export default function ProductCustomization(props) {
  const { options, customization } = props;

  const [inscription, setInscription] = useState("");

  useEffect(() => {
    let payload = {};
    if (inscription) {
      payload.inscription = { text: inscription };
    }
    props.onChange(payload);
  }, [inscription]);

  return (
    <Box>
      <Grid container>
        {options?.inscription && (
          <Grid item sm={12} xs={12}>
            <TextField
              id="inscription"
              value={customization.inscription?.text || ""}
              label="Inscription (optional)"
              name="inscription"
              variant="outlined"
              fullWidth
              onChange={(e) => setInscription(e.target.value)}
              helperText="Message to be written on the cake"
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
