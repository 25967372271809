import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import { generateItemSnippets, generateSizeSummary, normalizeFlavor } from "../util";
import HTMLViewer from "./HTMLViewer";
import IconText from "./IconText";
import { CheckOutlined } from "@mui/icons-material/";

export default function LineItemDetails(props) {
  const { item } = props;

  const flavor = normalizeFlavor(item.variant?.flavor);
  const snippets = generateItemSnippets(item);

  return (
    <Box pt={0.3}>
      {snippets.length > 0 && (
        <Box mb={0.3}>
          <Typography variant="overline" color="textSecondary">
            Item Details
          </Typography>
        </Box>
      )}
      {snippets.map((e, idx) => (
        <IconText key={idx} icon={CheckOutlined} text={e} variant="body2" />
      ))}
      {item.description && (
        <Box my={0.5}>
          <Typography variant="overline" color="textSecondary" display="block">
            NOTES
          </Typography>
          <HTMLViewer content={item.description} style={{ fontSize: ".85rem" }} />
        </Box>
      )}
    </Box>
  );
}
