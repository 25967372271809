import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Paper,
  AlertTitle
} from "@mui/material";
import ConfigContext from "../../common/context/ConfigContext";
import bkstApi from "../../common/api";
import BDatePicker from "../../common/component/BDatePicker";
import { shouldDisableDateV3, getDateRules, getAvailableTimeslotsbyDate, capitalize } from "../../common/util";
import moment from "moment";
import currency from "currency.js";
import CloseIcon from "@mui/icons-material/Close";
import AddressAutocomplete from "../../common/component/AddressAutocomplete";

export default function FulfillmentOptions(props) {
  const { cartId, items, options, fulfillment } = props;
  const siteConfig = useContext(ConfigContext);

  const [address, setAddress] = useState("");

  const [provider, setProvider] = useState("");
  const [quote, setQuote] = useState(options.shipping.delivery?.price || "");

  const [error, setError] = useState("");

  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [timeslots, setTimeslots] = useState([]);

  const switchToPickup = () => {
    props.onChange({ ...fulfillment, shipping: "pickup", location: options.shipping.pickup.locations[0], time: "" });
    handleClose();
  };

  const resetQuote = () => {
    setProvider("");
    setQuote("");
    setError("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    resetQuote();

    if (address) {
      if (!provider) {
        // get quote from fulfillment provider
        setProcessing(true);
        bkstApi
          .post(`/site/${siteConfig.id}/cart/${cartId}/delivery`, { address })
          .then((res) => {
            setQuote(res.data.quote);
            setProvider(res.data.provider);
            setProcessing(false);
          })
          .catch((err) => {
            setError(err.response?.data?.msg);
            setProcessing(false);
          });
      } else {
        props.onChange({
          ...fulfillment,
          shipping: "delivery",
          quote,
          provider,
          location: { ...address, timeslots: options.shipping.delivery.timeslots },
          time: ""
        });
        handleClose();
      }
    }
  };

  const handleClose = () => {
    resetQuote();

    setAddress("");
    setOpen(false);
  };

  useEffect(() => {
    if (fulfillment.location && fulfillment.date) {
      resetQuote();

      const dateRules = getDateRules(items, fulfillment.location.timeslots);

      // check if date is disabled
      if(shouldDisableDateV3(siteConfig.id, fulfillment.date, dateRules, fulfillment)){
        setTimeslots([]);
      } else {
        const slotsbyDate = getAvailableTimeslotsbyDate(fulfillment.location, fulfillment.date, dateRules);
        setTimeslots(slotsbyDate);
        //setTime(slotsbyDate[(slotsbyDate.length / 2) | 0]);
      }
    }
  }, [fulfillment.location, fulfillment.date]);

  const dateRules = getDateRules(items, fulfillment.location.timeslots);

  let CTA = <></>;
  let CONTENT = <></>;

  if (options.shipping.delivery?.type === "third-party" && options.shipping.delivery?.provider === "doordash") {
    CTA = (
      <Button
        component="a"
        target="_blank"
        href={options.shipping?.delivery?.link}
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleClose}
      >
        Order on DoorDash
      </Button>
    );
    CONTENT = (
      <Box>
        <Typography>
          We offer Delivery through DoorDash. Please click the link below to visit our DoorDash store and place your order for Delivery.
        </Typography>
      </Box>
    );
  } else if (provider) {
    CTA = (
      <Button type="submit" fullWidth variant="contained" color="primary">
        ADD DELIVERY
      </Button>
    );
    CONTENT = (
      <Box mt={2}>
        <Alert severity="success">
          <AlertTitle>Delivery is available!</AlertTitle>Verify address & delivery fee below, then click the Add Delivery button
        </Alert>
        <Box mt={3}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="overline" color="textSecondary">
                ADDRESS
              </Typography>
              <Typography variant="body1">{address.street}</Typography>
              <Typography variant="body1">
                {address.city}, {address.state}, {address.zip}
              </Typography>
              <Link size="small" sx={{ cursor: "pointer" }} onClick={() => setProvider("")}>
                change
              </Link>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="overline" color="textSecondary">
                DELIVERY FEE
              </Typography>
              <Typography variant="h3">{currency(quote).format()}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  } else {
    CTA = (
      <Button type="submit" fullWidth variant="contained" color="primary">
        CHECK AVAILABILITY
      </Button>
    );
    CONTENT = (
      <Box pt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AddressAutocomplete
              onPlaceSelect={(o) => {
                setProvider("");
                setQuote("");
                setAddress(o);
              }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box>
      <Paper elevation={0}>
        <Box>
          <ToggleButtonGroup color="primary" value={fulfillment.shipping} exclusive fullWidth>
            <ToggleButton value="pickup" onClick={switchToPickup}>
              Pickup
            </ToggleButton>
            <ToggleButton value="delivery" onClick={() => setOpen(true)} disabled={!options.shipping.delivery}>
              Delivery {!options.shipping.delivery && ` (not available)`}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box px={2} py={3}>
          {fulfillment.shipping === "pickup" && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Pickup location
              </Typography>
              <RadioGroup
                name="street"
                value={options.shipping.pickup.locations.findIndex((x) => x.street === fulfillment.location.street)}
                onChange={(e) => props.onChange({ ...fulfillment, shipping: "pickup", location: options.shipping.pickup.locations[e.target.value], time: "" })}
              >
                {options.shipping.pickup.locations.map((o, idx) => {
                  return (
                    <Box key={idx}>
                      <FormControlLabel value={idx} control={<Radio />} label={`${o.street}, ${o.city}, ${o.state} ${o.zip}`} />
                    </Box>
                  );
                })}
              </RadioGroup>
            </Box>
          )}
          {fulfillment.shipping === "delivery" && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Delivery Location
              </Typography>
              <FormControlLabel
                control={<Radio checked={true} />}
                label={`${fulfillment.location.street}, ${fulfillment.location.city}, ${fulfillment.location.state}, ${fulfillment.location.zip}`}
              />
              <Link size="small" sx={{ cursor: "pointer" }} onClick={() => setOpen(true)}>
                change
              </Link>
            </Box>
          )}
          <Box mt={3} pt={4} sx={{ borderTop: "1px solid #DDD" }}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <BDatePicker
                    value={fulfillment?.date || ""}
                    label={`${capitalize(fulfillment.shipping)} Date`}
                    onChange={(d) => props.onChange({ ...fulfillment, date: d, time: "" })}
                    disablePast
                    required
                    shouldDisableDate={(d) => shouldDisableDateV3(siteConfig.id, d, dateRules, fulfillment)}
                  />
                  <FormHelperText>{fulfillment.date && moment(fulfillment.date).format("dddd")}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={6} xs={6}>
                <FormControl variant="outlined" fullWidth required error={Boolean(fulfillment?.date && timeslots.length === 0)}>
                  <InputLabel id="time-label">{`${capitalize(fulfillment.shipping)} Time`}</InputLabel>
                  <Select
                    value={fulfillment.time || ""}
                    labelId="time-label"
                    label={`${capitalize(fulfillment.shipping)} Time`}
                    id="time"
                    name="time"
                    onChange={(e) => props.onChange({ ...fulfillment, time: e.target.value })}
                  >
                    {timeslots.map((i) => (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{fulfillment?.date && timeslots.length === 0 ? "No available timeslots for this date" : ""}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
      <Dialog open={open} onClose={handleClose} scroll="paper" fullWidth={true} maxWidth="sm">
        <form onSubmit={onSubmit}>
          <DialogTitle>
            {provider ? "Add Delivery" : "Enter Delivery Address"}{" "}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>{CONTENT}</Box>
            {error && (
              <Box mt={2}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button fullWidth variant="outlined" color="secondary" onClick={switchToPickup}>
              Switch to Pickup
            </Button>
            {CTA}
          </DialogActions>
        </form>
      </Dialog>
      {processing && (
        <Backdrop open={true} style={{ zIndex: "2000", color: "#fff" }}>
          <CircularProgress color="inherit" />
          <Box p={2} sx={{ display: "block" }}>
            <Typography sx={{ fontSize: "1.2rem" }}>Checking Availability...</Typography>
            <Typography>This may take 5-10 seconds</Typography>
          </Box>
        </Backdrop>
      )}
    </Box>
  );
}
