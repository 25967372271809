import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import bkstApi from "../../common/api";
import ConfigContext from "../../common/context/ConfigContext";
import currency from "currency.js";
import { isAssistedCheckout } from "../../common/util";
import moment from "moment";
import LatePaymentPolicy from "../../order/component/LatePaymentPolicy";
import OrderCancelationPolicy from "../../order/component/OrderCancelationPolicy";
import OrderRefundPolicy from "../../order/component/OrderRefundPolicy";
import StripePaymentElement from "../component/StripePaymentElement";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTheme } from "@mui/material/styles";
import OrderPaymentSummaryV2 from "../component/OrderPaymentSummaryV2";
import PhoneField from "../../common/component/PhoneField";
import { Link as RouterLink } from "react-router-dom";
import { AppStateContext } from "../../common/context/AppStateContext";
import StripeCardElement from "../../common/component/StripeCardElement";
import { useNavigate } from "react-router";
import LineItem from "../../common/component/LineItem";
import FulfillmentOptions from "../../cart/component/FulfillmentOptions";

const key = process.env.REACT_APP_STRIPE_KEY;
const promise = loadStripe(key);

export default function CartV3(props) {
  const siteConfig = useContext(ConfigContext);
  const navigate = useNavigate();

  const { setCartState } = useContext(AppStateContext);

  const theme = useTheme();
  const ref = useRef(null);

  const { cartId } = useParams();

  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");

  const [cart, setCart] = useState("");
  const [options, setOptions] = useState("");
  const [payment, setPayment] = useState("");

  const [fulfillment, setFulfillment] = useState({ shipping: "pickup" });

  const [tipRate, setTipRate] = useState("0");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [paymentDueType, setPaymentDueType] = useState("full");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const stripeOptions = {
    clientSecret,
    appearance: {
      labels: "floating",
      variables: {
        colorPrimary: theme.palette.primary.main,
        colorDanger: "#df1b41",
        borderRadius: "4px"
      }
    }
  };

  const staffAssist = isAssistedCheckout();

  useEffect(() => {
    window.scrollTo(0, 100);
    setProcessing(true);

    bkstApi(`/site/${siteConfig.id}/cartV2/${cartId}`).then((res) => {
      setClientSecret(res.data.cart.clientSecret);
      setPaymentIntentId(res.data.cart.paymentIntentId);

      setCart(res.data.cart);

      setPayment(res.data.cart.payment);
      setOptions(res.data.cart.options);
      setFulfillment({ shipping: "pickup", location: res.data.cart.options.shipping.pickup.locations[0], date: res.data.cart.shipping.date });

      setTipRate(res.data.cart.tipRate || "0");
      setPaymentDueType(+res.data.cart.payment.due < +res.data.cart.payment.total ? "deposit" : "full");

      setLoading(false);
      setProcessing(false);

      const itemCount = res.data.cart.items.reduce((acc, o) => +acc + +o.quantity, 0);
      setCartState(cartId, itemCount);
    });
  }, [cartId]);

  useEffect(() => {
    if (cart) {
      setPayment(recalculatePayment());

      // reset based on date
      if (paymentDueType === "deposit" && !isDepositAllowed()) {
        setPaymentDueType("full");
      }
    }
  }, [fulfillment, tipRate, paymentDueType]);

  const isDepositAllowed = () => {
    return fulfillment.date && moment(fulfillment.date).diff(moment(), "days") >= 7;
  };

  const recalculatePayment = () => {
    const subtotal = currency(cart.items.reduce((acc, o) => +acc + (+o.total || +o.price), 0));
    const delivery = currency((fulfillment.shipping === "delivery" && fulfillment.quote) || 0);
    const tipAmount = subtotal
      .add(delivery)
      .multiply(tipRate || 0)
      .divide(100);

    const total = subtotal.add(delivery).add(tipAmount);
    let due = total;

    if (paymentDueType === "deposit") {
      due = total.multiply(0.5);
    }

    return { subtotal: subtotal.value, delivery: delivery.value, tip: tipAmount.value, total: total.value, due: due.value };
  };

  const validate = async () => {
    // check required fields
    let errors = "";
    if (!fulfillment.date) {
      errors += "Date is missing. ";
    }
    if (!fulfillment.time) {
      errors += "Time is missing. ";
    }
    if (!cart.customer) {
      if (!name) {
        errors += "Name is missing. ";
      }
      if (!phone) {
        errors += "Phone is missing. ";
      } else if (phone.length < 10) {
        errors += "Invalid Phone Number. ";
      }

      if (!email) {
        errors += "Email is missing. ";
      } else if (email.length < 5 || !email.includes("@") || !email.includes(".")) {
        errors += "Invalid Email. ";
      }
    }

    if (errors) {
      setError(errors);
      return false;
    } else {
      setProcessing(true);

      const result = await bkstApi
        .post(`/site/${siteConfig.id}/cartV2/${cartId}/verify`, {
          paymentIntentId,
          shipping: { type: fulfillment.shipping, location: fulfillment.location, date: fulfillment.date, time: fulfillment.time },
          tipRate,
          due: payment.due,
          paymentDueType,
          customer: cart.customer || { name, email, phone }
        })
        .then((res) => {
          setCart(res.data.cart);
          setPayment(res.data.cart.payment);

          setProcessing(false);

          return true;
        })
        .catch((error) => {
          if (error?.response?.data) {
            if (error.response.data.cart) {
              setCart(error.response.data.cart);
              setPayment(error.response.data.cart.payment);
            }
            setError(error.response.data.msg);
          } else {
            setError("Unexpected error. Please refresh this page and try again");
          }
          setProcessing(false);

          return false;
        });

      return result;
    }
  };

  const chargePaymentMethod = (paymentMethodId) => {
    const payload = {
      paymentIntentId,
      paymentMethodId
    };

    bkstApi
      .post(`/site/${siteConfig.id}/moto`, payload)
      .then((res) => {
        navigate(`/payment-status?cart_id=${cartId}&payment_intent_client_secret=${clientSecret}`);
      })
      .catch((error) => {
        if (error?.response?.data) {
          setError(error.response.data.msg);
        } else {
          setError("Payment Declined. Please close this window and try again.");
        }
        setProcessing(false);

        return false;
      });
  };

  /*const adjustQuantity = async (idx, change) => {
    let items = cart.items;
    items[idx].quantity = +items[idx].quantity + change;

    let updatedCart = { ...cart, items };

    await bkstApi
      .put(`/site/${siteConfig.id}/cartV2/${cartId}`, {
        paymentIntentId,
        cart: updatedCart
      })
      .then((res) => {
        setCart(res.data.cart);
        setPayment(res.data.cart.payment);
        setProcessing(false);
        window.scrollTo(0, 100);
      })
      .catch((error) => {
        setError("Unexpected error. Please refresh this page and try again");
        setProcessing(false);
      });
  };*/

  const removeItem = async (idx) => {
    setProcessing(true);

    let items = cart.items;
    items.splice(idx, 1);

    let updatedCart = { ...cart, items };

    await bkstApi
      .put(`/site/${siteConfig.id}/cartV2/${cartId}`, {
        paymentIntentId,
        cart: updatedCart
      })
      .then((res) => {
        navigate(0);
      })
      .catch((error) => {
        navigate(0);
      });
  };

  if (loading) {
    return (
      <Box>
        <LinearProgress ref={ref} />{" "}
        {processing && (
          <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Box>
    );
  } else {
    const allowDeposit = isDepositAllowed();

    if (cart.items && cart.items.length > 0) {
      return (
        <Container maxWidth="sm" sx={{ paddingTop: "3rem", paddingBottom: "10rem" }}>
          <Box mb={3} ref={ref} textAlign={"center"}>
            <Typography variant="h2" gutterBottom>
              Checkout
            </Typography>
          </Box>
          <Dialog open={Boolean(error)} fullWidth={true} maxWidth="sm">
            <DialogTitle>Cart Error</DialogTitle>
            <DialogContent>
              <Typography>Please correct the errors below to proceed with your order. Your card has not been charged yet. </Typography>
              <Box mt={2} mb={4}>
                <Typography color="error">{error}</Typography>
              </Box>
              <Box>
                <Button variant="contained" color="primary" fullWidth onClick={() => setError("")}>
                  CLOSE
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
          <Typography variant="overline" color="textSecondary">
            ITEMS
          </Typography>
          <Paper elevation={0}>
            <Box px={2} py={2}>
              {cart.items.map((item, idx) => (
                <Box key={idx} mb={1}>
                  <LineItem item={item} view="min" />
                  {cart.type === "shop" && (
                    <Grid container spacing={2}>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={10}>
                        <Button size="small" onClick={() => removeItem(idx)} variant="text" color="secondary">
                          REMOVE ITEM
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              ))}
            </Box>
          </Paper>
          <Box my={4}>
            <FulfillmentOptions
              cartId={cartId}
              items={cart.items}
              options={options}
              fulfillment={fulfillment}
              onChange={(o) => {
                setFulfillment(o);
              }}
            />
          </Box>
          { siteConfig.id !== "ZJg1JT1mPlCPHop4Gfut" && <Box my={4}>
            <Paper elevation={0}>
              <ToggleButtonGroup color="primary" value={tipRate} exclusive onChange={(e) => setTipRate(e.target.value)} fullWidth>
                <ToggleButton value="0">No Tip</ToggleButton>
                <ToggleButton value="5">5%</ToggleButton>
                <ToggleButton value="10">10%</ToggleButton>
                <ToggleButton value="15">15%</ToggleButton>
                <ToggleButton value="20">20%</ToggleButton>
                <ToggleButton value="25">25%</ToggleButton>
              </ToggleButtonGroup>
            </Paper>
          </Box> }
          {!cart.customer && (
            <Box my={4}>
              <Typography variant="overline" color="textSecondary">
                CONTACT DETAILS
              </Typography>
              <Paper elevation={0}>
                <Box px={2} pt={3} pb={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          value={name}
                          variant="outlined"
                          fullWidth
                          id="name"
                          label="Name"
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          value={email}
                          variant="outlined"
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth>
                        <PhoneField name="Phone" value={phone} onChange={(phone) => setPhone(phone)} required />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          )}
          <Box my={4}>
            <Typography variant="overline" color="textSecondary">
              PAYMENT SUMMARY
            </Typography>
            <Paper elevation={0}>
              <Box px={2} py={2}>
                <Box mb={2}>
                  <RadioGroup
                    row
                    value={paymentDueType}
                    name="paymentDueType"
                    onChange={(e) => {
                      setPaymentDueType(e.target.value);
                      console.log("->", e.target.value);
                    }}
                  >
                    <FormControlLabel value="full" control={<Radio />} label="Pay in Full" />
                    <FormControlLabel
                      value="deposit"
                      control={<Radio />}
                      label={allowDeposit ? "Pay Deposit" : "Pay Deposit (only for orders due in more than 7 days)"}
                      disabled={!allowDeposit}
                    />
                  </RadioGroup>
                </Box>
                <OrderPaymentSummaryV2
                  subtotal={payment.subtotal}
                  delivery={payment.delivery}
                  tip={payment.tip}
                  total={payment.total}
                  due={payment.due}
                  date={moment(fulfillment.date).subtract(7, "days").format("MMM DD")}
                />
              </Box>
            </Paper>
          </Box>
          <Box my={4}>
            <Typography variant="overline" color="textSecondary" gutterBottom>
              Pay with
            </Typography>
            <Paper elevation={0}>
              <Box px={2} py={3}>
                {staffAssist ? (
                  <Elements stripe={promise}>
                    <StripeCardElement
                      disabled={loading}
                      validate={validate}
                      chargePaymentMethod={chargePaymentMethod}
                      amount={currency(payment.due).format()}
                      name={name}
                      phone={phone}
                    />
                  </Elements>
                ) : (
                  <Elements stripe={promise} options={stripeOptions}>
                    <StripePaymentElement
                      amount={currency(payment.due).format()}
                      disabled={loading}
                      validate={validate}
                      returnUrl={`${window.location.origin}/payment-status?cart_id=${cartId}`}
                      showPolicy={true}
                    />
                  </Elements>
                )}
              </Box>
            </Paper>
          </Box>
          <Box mt={10}>
            <OrderCancelationPolicy date={fulfillment.date} total={payment.total} tip={payment.tip} delivery={payment.delivery} ts={Date.now()} />
            <LatePaymentPolicy />
            <OrderRefundPolicy />
          </Box>
          {processing && (
            <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Container>
      );
    } else {
      return (
        <Box my={6} textAlign={"center"}>
          <Box mb={2}>
            <Typography variant="h2" gutterBottom>
              Your cart is empty!
            </Typography>
          </Box>
          <Button variant="contained" color="primary" size="large" component={RouterLink} to="/shop">
            Shop Now
          </Button>
        </Box>
      );
    }
  }
}
