import React from "react";
import { Alert, Box, Grid, Link, Paper, Typography } from "@mui/material";
import moment from "moment";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { capitalize, getCustomerFirstNameLastInitial, getCustomerName } from "../../common/util";

export default function OrderFulfillment(props) {
  const { order } = props;

  return (
    <Paper elevation={0}>
      {order.fulfillment.type === "delivery" && (
        <Alert severity="info" icon={<LocalShippingIcon fontSize="inherit" />}>
          Curbside delivery
        </Alert>
      )}
      <Box px={2} py={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(order.fulfillment.type)} address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{order.fulfillment.address}</Typography>
            { order.fulfillment.type === 'pickup' && <Link
              component="a"
              underline="always"
              target="_blank"
              href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(order.fulfillment.address)}`}
            >
              Get directions
            </Link> }
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(order.fulfillment.type)} date & time
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{`${moment(order.fulfillment.date).format("ddd, MMM DD, YYYY")} [${order.fulfillment.time}]`}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(order.fulfillment.type)} instructions
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {order.fulfillment.type === "pickup" && (
              <Box>
                <Box mb={0.5}>
                  <Typography color="textSecondary" variant="caption" gutterBottom>
                    When you arrive for pickup, please share the following information with the store clerk.
                  </Typography>
                </Box>
                <Typography variant="body2">
                  Pickup Code: {order.customer.phone?.slice(-4)} • Name: {getCustomerFirstNameLastInitial(getCustomerName(order.customer))}
                </Typography>
              </Box>
            )}
            <Typography variant="body2">{order.fulfillment.instructions}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
