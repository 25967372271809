import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import moment from "moment";
import ExpandableImage from "../image/ExpandableImage";
import Linkify from "react-linkify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function ChatBubble(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let style = {
    padding: "7px 10px 5px 10px",
    marginBottom: "1rem",
    maxWidth: isMobile ? "320px" : "480px",
    display: "inline-block",
    textAlign: "left",
    wordWrap: "break-word"
  };

  let justify = "flex-start";

  if (props.chat.uid) {
    style.backgroundColor = "#ececec";
  } else {
    style.backgroundColor = "#ceb4c1";
    justify = "flex-end";
  }

  return (
    <Box style={{ display: "flex", justifyContent: justify }}>
      <Paper elevation={0} style={style}>
        {props.chat.pics &&
          props.chat.pics.map((pic, idx) => {
            return (
              <Box key={idx}>
                <ExpandableImage key={idx} src={pic} style={{ width: "120px", borderRadius: "5px" }} />
              </Box>
            );
          })}
        <Linkify>
          <Typography variant="body2" display="inline">
            {props.chat.msg}
          </Typography>
        </Linkify>
        <Typography variant="caption" color="textSecondary" style={{ float: "right", marginLeft: "2rem" }}>
          {moment(props.chat.ts).format("h:mm A")}
        </Typography>
      </Paper>
    </Box>
  );
}
