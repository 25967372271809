import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Backdrop, Box, CircularProgress, Container, LinearProgress, Typography } from "@mui/material";
import Verify from "./Verify";
import Design from "../component/Design";
import CakeDetails from "../component/CakeDetails";
import ContactDetails from "../component/ContactDetails";
import Event from "../component/Event";
import ConfigContext from "../../common/context/ConfigContext";

export default function RequestForQuote(props) {
  const siteConfig = useContext(ConfigContext);

  let location = useLocation();
  const navigate = useNavigate();

  const [step, setStep] = useState("");
  const [progress, setProgress] = useState(0);

  const [req, setReq] = useState({});
  const [reqId, setReqId] = useState();

  const stepRef = useRef(null);

  useEffect(() => {
    if (location.pathname === `/get-quote` || location.pathname === `/get-quote/design`) {
      setStep("design");
      setProgress(10);
      stepRef.current.scrollIntoView();
    } else if (location.pathname === `/get-quote/customize` && req.pics) {
      setStep("customize");
      setProgress(50);
      stepRef.current.scrollIntoView();
    } else if (location.pathname === `/get-quote/photo` && location.state) {
      if (location.state && location.state.req) {
        setData(location.state.req);
      }

      setStep("customize");
      setProgress(50);
      stepRef.current.scrollIntoView();
    } else if (location.pathname === `/get-quote/event` && req.pics) {
      setStep("event");
      setProgress(75);
      stepRef.current.scrollIntoView();
    } else if (location.pathname === `/get-quote/contact` && req.pics && req.servings && req.location && req.budget) {
      setStep("contact");
      setProgress(95);
      stepRef.current.scrollIntoView();
    } else {
      navigate("/get-quote");
    }
  }, [location.pathname, location.hash]);

  const setData = (data) => {
    Object.assign(req, data);
    setReq(req);
  };

  const handleDesign = (data) => {
    setData(data);
    navigate("/get-quote/customize");
  };

  const handleEvent = (data) => {
    setData(data);
    navigate("/get-quote/contact");
  };

  const handleCustomize = (data) => {
    setData(data);
    navigate("/get-quote/event");
  };

  return (
    <Container maxWidth="md">
      <Box my={7} ref={stepRef}>
        <Typography variant="overline" color="textSecondary">
          Get a Quote
        </Typography>
        <LinearProgress color="primary" variant="determinate" value={progress} />
        {step === "design" && <Design onSubmit={handleDesign} setProgress={setProgress} />}
        {step === "customize" && (
          <Box>
            <CakeDetails pics={req.pics} onSubmit={handleCustomize} />
          </Box>
        )}
        {step === "event" && (
          <Box>
            <Event location={req.location} onSubmit={handleEvent} />
            {/* <Budget servings={req.servings} location={req.location} onSubmit={handleBudget} /> */}
          </Box>
        )}
        {step === "contact" && (
          <Box>
            <ContactDetails data={req} location={req.location} />
          </Box>
        )}
        {step === "submit" && (
          <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {step === "verify" && <Verify reqId={reqId} phone={req.phone} />}
      </Box>
    </Container>
  );
}
